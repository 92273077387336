/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { TitleCell_PlaylistFragmentDoc } from '../Cell/__generated__/TitleCell.graphql';
import { DescriptionCell_PlaylistFragmentDoc } from '../Cell/__generated__/DescriptionCell.graphql';
import { SizeCell_PlaylistFragmentDoc } from '../Cell/__generated__/SizeCell.graphql';
import { PlaylistTypeCell_PlaylistFragmentDoc } from '../Cell/__generated__/PlaylistTypeCell.graphql';
import { CreatedAtCell_PlaylistFragmentDoc } from '../Cell/__generated__/CreateAtCell.graphql';
import { UsedByCell_PlaylistFragmentDoc } from '../Cell/__generated__/UsedByCell.graphql';
import { ActionsCell_PlaylistFragmentDoc } from '../Cell/__generated__/ActionsCell.graphql';
export type PlaylistTable_PlaylistFragment = { __typename: 'Playlist', id: string, title: string, description?: string | null, size: any, playlistType: Types.PlaylistType, createdAt: any, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> };

export const PlaylistTable_PlaylistFragmentDoc = gql`
    fragment PlaylistTable_playlist on Playlist {
  id
  ...TitleCell_playlist
  ...DescriptionCell_playlist
  ...SizeCell_playlist
  ...PlaylistTypeCell_playlist
  ...CreatedAtCell_playlist
  ...UsedByCell_playlist
  ...ActionsCell_playlist
}
    ${TitleCell_PlaylistFragmentDoc}
${DescriptionCell_PlaylistFragmentDoc}
${SizeCell_PlaylistFragmentDoc}
${PlaylistTypeCell_PlaylistFragmentDoc}
${CreatedAtCell_PlaylistFragmentDoc}
${UsedByCell_PlaylistFragmentDoc}
${ActionsCell_PlaylistFragmentDoc}`;