import { gql } from '@apollo/client';
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { DurationModalType, HandleDurationModal } from '~components/playlists/DurationModals';
import { PlaylistDetail_PlaylistFragment } from '~components/playlists/PlaylistDetail/__generated__/PlaylistDetail.graphql';
import { MediaDurationIcon } from '~components/ui/icons/MediaDurationIcon';
import { formatSecondsToTimeString } from '~utils/durationConversion';
import { PlaylistTableMedia } from '../PlaylistMediaTable';

export function DurationCell({
  value,
}: PropsWithChildren<
  CellProps<
    PlaylistTableMedia,
    PlaylistTableMedia & {
      playlist: PlaylistDetail_PlaylistFragment;
    }
  >
>) {
  const { duration, isDefaultDuration, playlist } = value;
  const setDurationModal = useDisclosure();
  return (
    <>
      <HStack>
        <Button variant="unstyled" onClick={setDurationModal.onOpen}>
          <MediaDurationIcon boxSize="8" isEnabled={isDefaultDuration} />
          <span style={{ fontWeight: 'normal' }}>
            {isDefaultDuration ? 'Default' : formatSecondsToTimeString(duration)}
          </span>
        </Button>
      </HStack>
      <HandleDurationModal
        isOpen={setDurationModal.isOpen}
        playlist={playlist}
        onCancel={setDurationModal.onClose}
        selectedMedia={[value]}
        variant={DurationModalType.MediaDuration}
      />
    </>
  );
}

DurationCell.graphql = {
  fragments: {
    DurationCell_media: gql`
      fragment DurationCell_media on Media {
        id
        duration
        isDefaultDuration
      }
    `,
  },
};
