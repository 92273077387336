/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { TitleCell_MediaFragmentDoc } from '../Cell/__generated__/TitleCell.graphql';
import { SizeCell_MediaFragmentDoc } from '../Cell/__generated__/SizeCell.graphql';
import { DurationCell_MediaFragmentDoc } from '../Cell/__generated__/DurationCell.graphql';
import { FileTypeCell_MediaFragmentDoc } from '../Cell/__generated__/FileTypeCell.graphql';
import { CreatedAtCell_MediaFragmentDoc } from '../Cell/__generated__/CreatedAtCell.graphql';
export type PlaylistMediaTable_MediaFragment = { __typename: 'Media', id: string, title: string, size: any, duration: number, isDefaultDuration: boolean, type: string, createdAt: any };

export const PlaylistMediaTable_MediaFragmentDoc = gql`
    fragment PlaylistMediaTable_media on Media {
  id
  ...TitleCell_media
  ...SizeCell_media
  ...DurationCell_media
  ...FileTypeCell_media
  ...CreatedAtCell_media
}
    ${TitleCell_MediaFragmentDoc}
${SizeCell_MediaFragmentDoc}
${DurationCell_MediaFragmentDoc}
${FileTypeCell_MediaFragmentDoc}
${CreatedAtCell_MediaFragmentDoc}`;