/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type DurationCell_MediaFragment = { __typename: 'Media', id: string, duration: number, isDefaultDuration: boolean };

export const DurationCell_MediaFragmentDoc = gql`
    fragment DurationCell_media on Media {
  id
  duration
  isDefaultDuration
}
    `;