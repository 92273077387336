import { createIcon } from '@chakra-ui/react';

export const PlaylistImageIcon = createIcon({
  displayName: 'PlaylistImageIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M21.5 3.5H3.5C2.5 3.5 1.5 4.5 1.5 5.5V19.5C1.5 20.6 2.4 21.5 3.5 21.5H21.5C22.5 21.5 23.5 20.5 23.5 19.5V5.5C23.5 4.5 22.5 3.5 21.5 3.5ZM21.5 19.42C21.48 19.45 21.44 19.48 21.42 19.5H3.5V5.58L3.58 5.5H21.41C21.44 5.52 21.47 5.56 21.49 5.58V19.42H21.5ZM11.5 16.01L9 13L5.5 17.5H19.5L15 11.5L11.5 16.01Z"
      fill="currentColor"
    />
  ),
});
