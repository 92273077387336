/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PlaylistMediaTable_MediaFragmentDoc } from '../../PlaylistMediaTable/__generated__/PlaylistMediaTable.graphql';
import { SyncDisplaysModal_PlaylistFragmentDoc } from '../../__generated__/SyncDisplaysModal.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PlaylistDetail_CustomerFragment = { __typename: 'Customer', id: string, name: string };

export type PlaylistDetail_PlaylistFragment = { __typename: 'Playlist', id: string, title: string, description?: string | null, playlistType: Types.PlaylistType, defaultDuration: number, media: Array<{ __typename: 'Media', id: string, title: string, size: any, duration: number, isDefaultDuration: boolean, type: string, createdAt: any }>, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }> };

export type PlaylistUpdateMutationVariables = Types.Exact<{
  input: Types.PlaylistUpdateInput;
}>;


export type PlaylistUpdateMutation = { __typename: 'Mutation', playlistUpdate: { __typename: 'PlaylistUpdatePayload', playlist: { __typename: 'Playlist', id: string, title: string, description?: string | null, playlistType: Types.PlaylistType, defaultDuration: number, media: Array<{ __typename: 'Media', id: string, title: string, size: any, duration: number, isDefaultDuration: boolean, type: string, createdAt: any }>, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }> } } };

export const PlaylistDetail_CustomerFragmentDoc = gql`
    fragment PlaylistDetail_customer on Customer {
  id
  name
}
    `;
export const PlaylistDetail_PlaylistFragmentDoc = gql`
    fragment PlaylistDetail_playlist on Playlist {
  id
  title
  description
  playlistType
  defaultDuration
  media {
    ...PlaylistMediaTable_media
  }
  syncedDisplays: displays(filter: {state: SYNCED}) {
    id
  }
  outOfSyncDisplays: displays(filter: {state: OUT_OF_SYNC}) {
    id
  }
  syncingDisplays: displays(filter: {state: SYNCING}) {
    id
  }
  removingDisplays: displays(filter: {state: REMOVING}) {
    id
  }
  ...SyncDisplaysModal_playlist
}
    ${PlaylistMediaTable_MediaFragmentDoc}
${SyncDisplaysModal_PlaylistFragmentDoc}`;
export const PlaylistUpdateDocument = gql`
    mutation PlaylistUpdate($input: PlaylistUpdateInput!) {
  playlistUpdate(input: $input) {
    playlist {
      id
      ...PlaylistDetail_playlist
    }
  }
}
    ${PlaylistDetail_PlaylistFragmentDoc}`;
export type PlaylistUpdateMutationFn = ApolloReactCommon.MutationFunction<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>;

/**
 * __usePlaylistUpdateMutation__
 *
 * To run a mutation, you first call `usePlaylistUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaylistUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playlistUpdateMutation, { data, loading, error }] = usePlaylistUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaylistUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>(PlaylistUpdateDocument, options);
      }
export type PlaylistUpdateMutationHookResult = ReturnType<typeof usePlaylistUpdateMutation>;
export type PlaylistUpdateMutationResult = ApolloReactCommon.MutationResult<PlaylistUpdateMutation>;
export type PlaylistUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>;