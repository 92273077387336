import {
  Box,
  Slider as ChakraSlider,
  SliderFilledTrack as ChakraSliderFilledTrack,
  SliderThumb,
  SliderTrack as ChakraSliderTrack,
  Tooltip,
  UseSliderProps,
} from '@chakra-ui/react';
import { warn } from '@chakra-ui/utils';
import { ComponentType, useState } from 'react';
import { IconProps } from './icons';

type Props = UseSliderProps & {
  minScale?: number;
  maxScale?: number;
  leftIcon?: ComponentType<IconProps>;
  rightIcon?: ComponentType<IconProps>;
  labelSuffix?: string;
};

export function Slider({
  value,
  onChange,
  onChangeEnd,
  isDisabled,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  min = 0,
  max = 100,
  minScale = min,
  maxScale = max,
  labelSuffix = '%',
  ...rest
}: Props) {
  warn({
    condition: min < minScale,
    message: '`min` cannot be lower than `minScale`',
  });
  warn({
    condition: max > maxScale,
    message: '`max` cannot be greater than `maxScale`',
  });
  const [showTooltip, setShowTooltip] = useState(false);

  const scaleDistance = maxScale - minScale;
  const minDistance = min - minScale;
  const maxDistance = maxScale - max;

  const minSpacerPercentage = (minDistance / scaleDistance) * 100;
  const maxSpacerPercentage = (maxDistance / scaleDistance) * 100;

  return (
    <Box position="relative" display="flex" alignItems="center">
      {LeftIcon && (
        <Box marginRight="3" color="gray.400" display="flex" alignItems="center">
          <LeftIcon />
        </Box>
      )}
      <Box display="flex" alignItems="center" flex="1">
        <ChakraSlider
          value={value}
          onChange={onChange}
          onChangeEnd={onChangeEnd}
          focusThumbOnChange={false}
          isDisabled={isDisabled}
          min={min}
          max={max}
          {...rest}
          display="flex"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <Box
            display="flex"
            alignItems="center"
            background={isDisabled ? 'gray.200' : 'gray.300'}
            height="4px"
            width="100%"
            borderRadius="sm"
          >
            <Box width={`${minSpacerPercentage}%`} />
            <Box position="relative" flex="1">
              <ChakraSliderTrack>
                <ChakraSliderFilledTrack background={isDisabled ? 'gray.100' : 'blue.500'} />
              </ChakraSliderTrack>
              <Tooltip
                bg="white"
                color="blue.800"
                border="1px solid"
                borderColor="gray.100"
                boxShadow="0px 1px 2px 0px #00385F0F, 0px 1px 3px 0px #00385F1A"
                borderRadius="md"
                paddingX="4"
                paddingY="2"
                placement="bottom"
                fontSize="md"
                isOpen={showTooltip}
                label={`${value}${labelSuffix}`}
              >
                <SliderThumb />
              </Tooltip>
            </Box>
            <Box width={`${maxSpacerPercentage}%`} />
          </Box>
        </ChakraSlider>
      </Box>
      {RightIcon && (
        <Box marginLeft="3" color="gray.400" display="flex" alignItems="center">
          <RightIcon height="6" width="6" />
        </Box>
      )}
    </Box>
  );
}
