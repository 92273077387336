import { isEmpty, isNil } from 'lodash';
import {
  UseContentSource_ContentSource_AppContentSource_Fragment,
  UseContentSource_ContentSource_BookmarkContentSource_Fragment,
  UseContentSource_ContentSource_InputContentSource_Fragment,
  UseContentSource_ContentSource_PlaylistContentSource_Fragment,
  UseContentSource_ContentSourceFragment,
  UseContentSource_DisplayFragment,
} from '~components/displays/__generated__/useContentSource.graphql';
import { OrganizationAppSubscription } from '~components/displays/BulkAction/BulkManageApps';
import { PlaybackSection_DisplayFragment } from '~components/displays/DisplayDetail/playback/__generated__/PlaybackSection.graphql';
import { AppSubscription } from '~components/displays/useManageAppSubscriptionsForm';
import { DisplayPlatform, Power } from '~graphql/__generated__/types';
import { ToggleOptionsConfig } from './DisplayDetail/DisplayControlsToggle';

export function mapStringToPowerEnum(value: string) {
  switch (value) {
    case 'ON':
      return Power.On;
    case 'STANDBY':
      return Power.Standby;
    default:
      throw new Error(`Power value not recognized: ${value}`);
  }
}

export function mapPowerEnumToLabel(power: Power): string {
  switch (power) {
    case Power.On:
      return 'On';
    case Power.Standby:
      return 'Standby';
  }
}

export function getPlatformLabel(platform: Pick<DisplayPlatform, 'name' | 'version'>) {
  return [platform.name, platform.version]
    .filter((value) => !isNil(value) && value !== 'undefined')
    .join(' - ');
}

export function formatDownloadProgress(progress: number) {
  return `${Math.round((progress + Number.EPSILON) * 100)}%`;
}

export function isBrowserSource(source: string | undefined) {
  return source?.toLowerCase() === 'browser';
}

export function isMediaPlayerSource(source: string | undefined) {
  return source?.toLowerCase() === 'media player';
}

export function isContentSourcesSupported(
  display: UseContentSource_DisplayFragment | PlaybackSection_DisplayFragment,
): boolean {
  return !isEmpty(display.contentSource?.available);
}
export function isDefaultContentSourcesSupported(
  display: UseContentSource_DisplayFragment | PlaybackSection_DisplayFragment,
): boolean {
  return !isEmpty(display.contentSource?.default);
}

export function isAppContentSource(
  source: UseContentSource_ContentSourceFragment | undefined,
): source is UseContentSource_ContentSource_AppContentSource_Fragment {
  return source?.__typename === 'AppContentSource';
}

export function isBookmarkContentSource(
  source: UseContentSource_ContentSourceFragment | undefined,
): source is UseContentSource_ContentSource_BookmarkContentSource_Fragment {
  return source?.__typename === 'BookmarkContentSource';
}

export function isInputContentSource(
  source: UseContentSource_ContentSourceFragment | undefined,
): source is UseContentSource_ContentSource_InputContentSource_Fragment {
  return source?.__typename === 'InputContentSource';
}

export function isPlaylistContentSource(
  source: UseContentSource_ContentSourceFragment | undefined,
): source is UseContentSource_ContentSource_PlaylistContentSource_Fragment {
  return source?.__typename === 'PlaylistContentSource';
}

export function contentSourceToStringValue(source: UseContentSource_ContentSourceFragment): string {
  const value = `contentSource|${source.__typename}`;

  if (isAppContentSource(source)) {
    return [
      value,
      source.applicationId,
      ...(source.selectedActivity ? [source.selectedActivity] : []),
    ].join('|');
  }

  if (isBookmarkContentSource(source)) {
    return `${value}|${source.index}`;
  }

  if (isInputContentSource(source)) {
    return `${value}|${source.source}`;
  }

  if (isPlaylistContentSource(source)) {
    return `${value}|${source.playlistId}`;
  }

  throw new Error(`Unsupported input source: ${JSON.stringify(source, null, 2)}`);
}

export function toAvailableControlToggleOptions<T extends string>(
  defaultOptions: Array<ToggleOptionsConfig<T>>,
  availableOptions: T[] = [],
) {
  if (availableOptions.length === 0) {
    return defaultOptions;
  }
  return defaultOptions.filter(({ value }) => availableOptions.includes(value));
}

export function getSubscriptionVersion(
  appSubscription: AppSubscription | OrganizationAppSubscription,
) {
  return 'appInstallation' in appSubscription &&
    (appSubscription.appInstallation?.__typename === 'AppInstallationInstalling' ||
      appSubscription.appInstallation?.__typename === 'AppInstallationInstalled' ||
      appSubscription.appInstallation?.__typename === 'AppInstallationUninstalling')
    ? appSubscription.appInstallation.versionName
    : null;
}
