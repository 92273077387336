/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PlaylistTable_PlaylistFragmentDoc } from '../../../../../../components/playlists/PlaylistTable/__generated__/PlaylistTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerPlaylistsPageQueryVariables = Types.Exact<{
  customerHandle: Types.Scalars['String'];
}>;


export type CustomerPlaylistsPageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, playlists: Array<{ __typename: 'Playlist', id: string, title: string, description?: string | null, size: any, playlistType: Types.PlaylistType, createdAt: any, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }>, allDisplays: Array<{ __typename: 'Display', id: string }> }>, waveSubscription?: { __typename: 'WaveSubscription', usage: { __typename: 'SubscriptionUsage', current: number, max?: number | null } } | null } | null };


export const CustomerPlaylistsPageDocument = gql`
    query CustomerPlaylistsPage($customerHandle: String!) {
  customer: customerByHandle(handle: $customerHandle) {
    id
    playlists {
      ...PlaylistTable_playlist
    }
    waveSubscription {
      usage {
        current
        max
      }
    }
  }
}
    ${PlaylistTable_PlaylistFragmentDoc}`;

/**
 * __useCustomerPlaylistsPageQuery__
 *
 * To run a query within a React component, call `useCustomerPlaylistsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPlaylistsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPlaylistsPageQuery({
 *   variables: {
 *      customerHandle: // value for 'customerHandle'
 *   },
 * });
 */
export function useCustomerPlaylistsPageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CustomerPlaylistsPageQuery, CustomerPlaylistsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerPlaylistsPageQuery, CustomerPlaylistsPageQueryVariables>(CustomerPlaylistsPageDocument, options);
      }
export function useCustomerPlaylistsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerPlaylistsPageQuery, CustomerPlaylistsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerPlaylistsPageQuery, CustomerPlaylistsPageQueryVariables>(CustomerPlaylistsPageDocument, options);
        }
export type CustomerPlaylistsPageQueryHookResult = ReturnType<typeof useCustomerPlaylistsPageQuery>;
export type CustomerPlaylistsPageLazyQueryHookResult = ReturnType<typeof useCustomerPlaylistsPageLazyQuery>;
export type CustomerPlaylistsPageQueryResult = ApolloReactCommon.QueryResult<CustomerPlaylistsPageQuery, CustomerPlaylistsPageQueryVariables>;