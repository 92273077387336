import { FormControl, FormLabel, GridItem, Stack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import gql from 'graphql-tag';
import { isNil } from 'lodash';
import { useAuth } from '~auth/useAuth';
import { DisplayDefaultContentSource } from '~components/displays/DisplayDetail/playback/DisplayDefaultContentSource';
import { DisplayLedStripColorSelect } from '~components/displays/DisplayDetail/playback/DisplayLedStripColorSelect';
import { DisplayOrientationToggle } from '~components/displays/DisplayDetail/playback/DisplayOrientationToggle';
import { isDefaultContentSourcesSupported as isDefaultContentSourcesSupportedByDisplay } from '~components/displays/utils';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { FeatureFlag } from '~utils/features';
import { PlaybackSection_DisplayFragment } from './__generated__/PlaybackSection.graphql';
import { DisplayBrightnessSlider } from './DisplayBrightnessSlider';
import { DisplayVolumeLimits } from './DisplayVolumeLimits';

interface Props {
  display: PlaybackSection_DisplayFragment;
}

export function PlaybackSection({ display }: Props) {
  const isDefaultContentSourcesSupported = isDefaultContentSourcesSupportedByDisplay(display);
  const { verifyUserPermissions } = useAuth();

  const hasDisplaySettingsUpdatePermission = verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <>
      <GridItem colSpan={{ base: 8, md: 7 }}>
        <Stack direction="column" spacing="10">
          {isDefaultContentSourcesSupported && <DisplayDefaultContentSource display={display} />}

          <DisplayOrientationToggle display={display} />
        </Stack>
      </GridItem>

      {!isNil(display.brightness) && (
        <FeatureFlag feature="brightness">
          <GridItem colSpan={{ base: 8, md: 7 }} mt={10} maxWidth="md">
            <FormControl>
              <FormLabel>
                Brightness
                <FormLabelPendingIndicator isPending={isPropertyPending(display.brightness)} />
              </FormLabel>
              <DisplayBrightnessSlider
                display={display}
                isDisabled={!hasDisplaySettingsUpdatePermission}
              />
            </FormControl>
          </GridItem>
        </FeatureFlag>
      )}

      {display.ledStripColor !== null && (
        <GridItem colSpan={{ base: 8, sm: 4, lg: 8, xl: 3 }} mt={10}>
          <FormControl>
            <FormLabel>
              Led strip
              <FormLabelPendingIndicator isPending={isPropertyPending(display.ledStripColor)} />
            </FormLabel>
            <DisplayLedStripColorSelect
              display={display}
              isDisabled={!hasDisplaySettingsUpdatePermission}
            />
          </FormControl>
        </GridItem>
      )}

      <DisplayVolumeLimits display={display} />
    </>
  );
}

PlaybackSection.graphql = {
  fragments: {
    PlaybackSection_display: gql`
      fragment PlaybackSection_display on Display {
        id
        ...DisplayVolumeLimits_display
        ...DisplayDefaultContentSource_display
        ...DisplayOrientationToggle_display
        ...DisplayLedStripColorSelect_display
        ...DisplayBrightnessSlider_display
      }
    `,
  },
};
