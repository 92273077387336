import { createIcon } from '@chakra-ui/react';

export const PlaylistVideoIcon = createIcon({
  displayName: 'PlaylistVideoIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M15.5 8.5V16.5H5.5V8.5H15.5ZM16.5 6.5H4.5C3.95 6.5 3.5 6.95 3.5 7.5V17.5C3.5 18.05 3.95 18.5 4.5 18.5H16.5C17.05 18.5 17.5 18.05 17.5 17.5V14L21.5 18V7L17.5 11V7.5C17.5 6.95 17.05 6.5 16.5 6.5Z"
      fill="currentColor"
    />
  ),
});
