import { gql } from '@apollo/client';
import { AlertTitle, Box, Button, HStack, useDisclosure, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { Columns } from '~components/displays/DisplayTable/constants';
import {
  DisplaysQueryContext,
  useDisplaysQuery,
} from '~components/displays/DisplayTable/useDisplaysQuery';
import { statusMessageMap, StatusTypes } from '~components/displays/useStatus';
import { WarningAlert } from '~components/ui/Alert';
import { BackButton } from '~components/ui/BackButton';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { DisplaysDetailStats } from '~components/ui/DisplaysDetailStats';
import { FileUploadButton } from '~components/ui/FileUploadButton';
import { MediaDurationIcon } from '~components/ui/icons/MediaDurationIcon';
import { PageHeading } from '~components/ui/PageHeading';
import { PlaylistType, PlaylistUpdateInput } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { formatSecondsToTimeString } from '~utils/durationConversion';
import { fromError } from '~utils/errors';
import { useFeatureFlag } from '~utils/features';
import { MaybePromise, OmitStrict } from '~utils/types';
import { useLimits } from '~utils/useLimits';
import { DeletePlaylistsModal } from '../DeletePlaylistsModal';
import { DurationModalType, HandleDurationModal } from '../DurationModals';
import { EditPlaylistModal } from '../EditPlaylistModal';
import {
  PlaylistMediaTable,
  usePlaylistMediaTable,
} from '../PlaylistMediaTable/PlaylistMediaTable';
import { SyncDisplaysModal } from '../SyncDisplaysModal';
import { useMediaList } from '../useMediaList';
import { useRemovePlaylistFromDisplays } from '../useRemovePlaylistFromDisplays';
import {
  PlaylistDetail_CustomerFragment,
  PlaylistDetail_PlaylistFragment,
  usePlaylistUpdateMutation,
} from './__generated__/PlaylistDetail.graphql';
import { PlaylistDetailMoreActions } from './PlaylistDetailMoreActions';

interface Props {
  customer: PlaylistDetail_CustomerFragment;
  playlist: PlaylistDetail_PlaylistFragment;
  onPlaylistUpdateSuccess: () => MaybePromise<void>;
}

export type FormValues = OmitStrict<PlaylistUpdateInput, 'mediaIds'>;

export function PlaylistDetail({ customer, playlist, onPlaylistUpdateSuccess }: Props) {
  const toast = useToast();
  const navigate = useNavigate();
  const syncDisplaysModal = useDisclosure();
  const deletePlaylistsModal = useDisclosure();
  const editPlaylistModal = useDisclosure();
  const setDurationModal = useDisclosure();
  const setDefaultDurationModal = useDisclosure();
  const { removePlaylistFromDisplays } = useRemovePlaylistFromDisplays();
  const analytics = useAnalyticsReporter();
  const { setDisplaysQuery } = useContext(DisplaysQueryContext);
  const { verifyUserPermissions } = useAuth();

  const hasPlaylistUpdatePermission = verifyUserPermissions([Permission.PlaylistUpdate]);

  const handleDeletePlaylistsSuccess = () => {
    deletePlaylistsModal.onClose();
    analytics.track('playlistDelete');
    navigate('../playlists');
  };

  const [updatePlaylist] = usePlaylistUpdateMutation();

  const { mediaList, reinitializeMediaList, updateMedia } = useMediaList({
    customerId: customer.id,
    initialMedia: playlist.media,
  });

  const { isEnabled: isPlaylistTypesEnabled } = useFeatureFlag('playlistTypes');

  const handleGoBack = () => {
    navigate('../playlists');
  };

  useEffect(() => {
    reinitializeMediaList(playlist.media);
  }, [playlist, reinitializeMediaList]);

  const handleRowMove = async (dragIndex: number, hoverIndex: number) => {
    const dragRecord = mediaList[dragIndex];
    const mediaWithoutDraggedItem = mediaList.filter((_, i) => i !== dragIndex);
    const newList = [
      ...mediaWithoutDraggedItem.slice(0, hoverIndex),
      dragRecord,
      ...mediaWithoutDraggedItem.slice(hoverIndex),
    ];
    const { data } = await updatePlaylist({
      variables: {
        input: {
          playlistId: playlist.id,
          mediaIds: newList.map((m) => m.id),
        },
      },
    });

    if (!data) {
      throw new Error('No data');
    }

    reinitializeMediaList(data.playlistUpdate.playlist.media);
  };

  const performBulkMediaRemoval = async (ids: string[]) => {
    try {
      const updatedList = mediaList.filter((m) => !ids.includes(m.id));

      const { data } = await updatePlaylist({
        variables: {
          input: {
            playlistId: playlist.id,
            title: playlist.title,
            description: playlist.description,
            mediaIds: updatedList.map((m) => m.id),
          },
        },
      });

      if (!data) {
        throw new Error('No data');
      }

      analytics.track('playlistUpdate');

      reinitializeMediaList(data.playlistUpdate.playlist.media);
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot edit playlist',
        description: fromError(err, 'EditPlaylist'),
      });
    }
  };

  const table = usePlaylistMediaTable(playlist, mediaList, {
    onRemoveMedia: performBulkMediaRemoval,
  });

  const selectedMedia = table.selectedFlatRows.map((r) => r.original);

  const performMediaUpload = async (fileList: FileList) => {
    try {
      const newMediaList = await updateMedia(fileList);

      if (newMediaList.length === 0) {
        return;
      }

      const { data } = await updatePlaylist({
        variables: {
          input: {
            playlistId: playlist.id,
            mediaIds: newMediaList.map((m) => m.id),
          },
        },
      });

      if (!data) {
        throw new Error('No data');
      }

      reinitializeMediaList(data.playlistUpdate.playlist.media);
      analytics.track('playlistUpdate');

      await onPlaylistUpdateSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot edit playlist',
        description: fromError(err, 'EditPlaylist', {
          MEDIA_NOT_FOUND: 'The media you are trying to save has not been found',
          INVALID_MIME_TYPE:
            "Looks like the type of the file you're trying to upload is not supported.",
        }),
      });
    }
  };

  const handlePlaylistEdit = async () => {
    await onPlaylistUpdateSuccess();
    editPlaylistModal.onClose();
    analytics.track('playlistUpdate');
  };

  const removeDisplaysAction = useDestructiveAction<undefined>({
    title: 'Remove playlist from displays',
    message: 'Are you sure you want remove this playlist from all displays?',
    notice: 'The displays will no longer be able to play this content.',
    confirmLabel: 'Remove',
    onConfirm: async () => {
      await removePlaylistFromDisplays(playlist.id);
    },
  });

  const bulkDeleteMediaAction = useDestructiveAction<undefined>({
    title: 'Delete media',
    message: 'The media you selected will be permanently removed.',
    notice: 'This action cannot be undone.',
    confirmLabel: 'Delete files',
    onConfirm: async () => {
      await performBulkMediaRemoval(selectedMedia.map((m) => m.id));
    },
  });

  const { persistFilters } = useDisplaysQuery();
  const {
    playlists: { isPlaylistMaximumFileLimitReached },
  } = useLimits();

  const canGoToLinkedDisplays = playlist.syncedDisplays.length > 0;
  const mediaLimitReached = isPlaylistMaximumFileLimitReached(playlist.media.length);
  const canUploadMedia = !mediaLimitReached;

  const handleGoToLinkedDisplays = (
    playlistTitle: string,
    filters: Partial<Record<Columns, string>> = {},
  ) => {
    persistFilters(
      [
        {
          id: Columns.Playlist,
          value: [{ column: Columns.Playlist, value: playlistTitle, label: playlistTitle }],
        },
        ...Object.entries(filters).reduce<Parameters<typeof persistFilters>[0]>(
          (acc, [key, value]) => {
            acc.push({
              id: key,
              value: [{ column: key, value, label: value }],
            });
            return acc;
          },
          [],
        ),
      ],
      { reset: true },
    );

    const searchParams = new URLSearchParams({ playlist: playlistTitle, ...filters });
    setDisplaysQuery?.(searchParams);

    navigate(`../displays?${searchParams.toString()}`);
  };

  return (
    <>
      <PageHeading
        floatingButton={<BackButton onClick={handleGoBack} />}
        description={playlist.description || undefined}
        actions={
          <HStack>
            <FileUploadButton
              leftIcon={undefined}
              variant="solid"
              colorScheme="blue"
              onChange={performMediaUpload}
              isDisabled={!hasPlaylistUpdatePermission || !canUploadMedia}
              playlistType={playlist.playlistType}
            >
              Add media
            </FileUploadButton>
            <PlaylistDetailMoreActions
              playlist={playlist}
              onRename={editPlaylistModal.onOpen}
              onSync={syncDisplaysModal.onOpen}
              onRemoveDisplays={() => removeDisplaysAction.askConfirmation(undefined)}
              onDelete={deletePlaylistsModal.onOpen}
            />
          </HStack>
        }
      >
        {playlist.title}
      </PageHeading>

      {mediaLimitReached && (
        <WarningAlert>
          <AlertTitle>
            You have reach the limit of{' '}
            {process.env.REACT_APP_PLAYLISTS_MAXIMUM_MEDIA_ENTRIES ?? ''} assets for this playlist.
          </AlertTitle>
        </WarningAlert>
      )}

      <HStack justifyContent="space-between" alignItems="flex-start" marginTop={'8'}>
        <DisplaysDetailStats
          syncedCount={playlist.syncedDisplays.length}
          outOfSyncCount={playlist.outOfSyncDisplays.length}
          syncingCount={playlist.syncingDisplays.length}
          removingCount={playlist.removingDisplays.length}
          onClickSyncedDisplays={
            canGoToLinkedDisplays ? () => handleGoToLinkedDisplays(playlist.title) : undefined
          }
          onClickOutOfSyncDisplays={() =>
            handleGoToLinkedDisplays(playlist.title, {
              [Columns.Warnings]: statusMessageMap[StatusTypes.PlaylistOutOfSync],
            })
          }
        />
        {!isEmpty(selectedMedia) ? (
          <Button
            variant={'solid'}
            colorScheme={'red'}
            onClick={() => bulkDeleteMediaAction.askConfirmation(undefined)}
            isDisabled={!hasPlaylistUpdatePermission}
          >
            Delete selected
          </Button>
        ) : undefined}
      </HStack>
      {playlist.playlistType === PlaylistType.Image && isPlaylistTypesEnabled && (
        <HStack my={6} spacing={8}>
          <Button
            variant="outline"
            size="sm"
            isDisabled={isEmpty(selectedMedia)}
            onClick={setDurationModal.onOpen}
          >
            Set duration
          </Button>

          <Button variant="unstyled" color="#1D85E8" onClick={setDefaultDurationModal.onOpen}>
            <HStack spacing={3}>
              <MediaDurationIcon boxSize="8" isEnabled />
              <span>Default duration : {formatSecondsToTimeString(playlist.defaultDuration)}</span>
            </HStack>
          </Button>
        </HStack>
      )}

      <Box
        boxShadow="elevated"
        border="1px solid"
        borderRadius="md"
        borderColor="gray.100"
        overflowX="auto"
        background="white"
        marginTop={2}
      >
        <PlaylistMediaTable
          playlist={playlist}
          table={table}
          onRowMove={handleRowMove}
          onFileUpload={performMediaUpload}
        />
      </Box>

      {removeDisplaysAction.confirmationNode}
      {bulkDeleteMediaAction.confirmationNode}
      <SyncDisplaysModal
        isOpen={syncDisplaysModal.isOpen}
        playlist={playlist}
        onSuccess={syncDisplaysModal.onClose}
        onCancel={syncDisplaysModal.onClose}
      />
      <DeletePlaylistsModal
        isOpen={deletePlaylistsModal.isOpen}
        playlists={[playlist]}
        onCancel={deletePlaylistsModal.onClose}
        onSuccess={handleDeletePlaylistsSuccess}
      />
      <EditPlaylistModal
        isOpen={editPlaylistModal.isOpen}
        playlist={playlist}
        onCancel={editPlaylistModal.onClose}
        onSuccess={handlePlaylistEdit}
      />
      {playlist.playlistType === PlaylistType.Image && (
        <>
          <HandleDurationModal
            isOpen={setDurationModal.isOpen}
            playlist={playlist}
            onCancel={setDurationModal.onClose}
            selectedMedia={selectedMedia}
            variant={DurationModalType.MediaDuration}
          />
          <HandleDurationModal
            isOpen={setDefaultDurationModal.isOpen}
            onCancel={setDefaultDurationModal.onClose}
            playlist={playlist}
            selectedMedia={[]}
            variant={DurationModalType.PlaylistDefaultDuration}
          />
        </>
      )}
    </>
  );
}

PlaylistDetail.graphql = {
  fragments: {
    PlaylistDetail_customer: gql`
      fragment PlaylistDetail_customer on Customer {
        id
        name
      }
    `,
    PlaylistDetail_playlist: gql`
      fragment PlaylistDetail_playlist on Playlist {
        id
        title
        description
        playlistType
        defaultDuration
        media {
          ...PlaylistMediaTable_media
        }
        syncedDisplays: displays(filter: { state: SYNCED }) {
          id
        }
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
        syncingDisplays: displays(filter: { state: SYNCING }) {
          id
        }
        removingDisplays: displays(filter: { state: REMOVING }) {
          id
        }
        ...SyncDisplaysModal_playlist
      }
    `,
  },
  mutations: {
    PlaylistUpdate: gql`
      mutation PlaylistUpdate($input: PlaylistUpdateInput!) {
        playlistUpdate(input: $input) {
          playlist {
            id
            ...PlaylistDetail_playlist
          }
        }
      }
    `,
  },
};
