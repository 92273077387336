/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
export type PlaylistTypeCell_PlaylistFragment = { __typename: 'Playlist', id: string, playlistType: Types.PlaylistType };

export const PlaylistTypeCell_PlaylistFragmentDoc = gql`
    fragment PlaylistTypeCell_playlist on Playlist {
  id
  playlistType
}
    `;