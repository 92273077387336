import { Icon, IconProps } from '@chakra-ui/react';

interface MediaDurationIconProps extends IconProps {
  isEnabled?: boolean;
}

export const MediaDurationIcon = ({ isEnabled = false, ...props }: MediaDurationIconProps) => {
  const circleFillColor = isEnabled ? '#F1F8FE' : '#D9E2EC';
  const pathFillColor = isEnabled ? '#1D85E8' : '#334E68';

  return (
    <Icon viewBox="0 0 32 33" {...props}>
      <g>
        <circle cx="16" cy="16.6665" r="16" fill={circleFillColor} />
        <path
          d="M16.9375 9.51709V9.146C16.9375 8.89736 16.8387 8.6589 16.6629 8.48308C16.4871 8.30727 16.2486 8.2085 16 8.2085C15.7514 8.2085 15.5129 8.30727 15.3371 8.48308C15.1613 8.6589 15.0625 8.89736 15.0625 9.146V9.51709C13.8786 9.66571 12.7476 10.0961 11.7645 10.7722L11.3516 10.3569C11.1754 10.1808 10.9366 10.0819 10.6875 10.0819C10.4384 10.0819 10.1996 10.1808 10.0234 10.3569C9.84732 10.5331 9.74838 10.7719 9.74838 11.021C9.74838 11.2701 9.84732 11.5089 10.0234 11.6851L10.3594 12.021C9.15946 13.3858 8.49837 15.1412 8.5 16.9585C8.5 21.094 11.8645 24.4585 16 24.4585C20.1355 24.4585 23.5 21.094 23.5 16.9585C23.5 13.1405 20.632 9.97959 16.9375 9.51709ZM16 18.8335C15.5578 18.8334 15.1299 18.677 14.7918 18.3919C14.4537 18.1068 14.2273 17.7114 14.1525 17.2756C14.0778 16.8397 14.1594 16.3915 14.3831 16.01C14.6068 15.6286 14.9581 15.3384 15.375 15.1909V12.271C15.375 12.1052 15.4409 11.9463 15.5581 11.8291C15.6753 11.7118 15.8342 11.646 16 11.646C16.1658 11.646 16.3247 11.7118 16.4419 11.8291C16.5592 11.9463 16.625 12.1052 16.625 12.271V15.1909C17.0419 15.3384 17.3932 15.6286 17.6169 16.01C17.8406 16.3915 17.9222 16.8397 17.8475 17.2756C17.7727 17.7114 17.5463 18.1068 17.2082 18.3919C16.8701 18.677 16.4422 18.8334 16 18.8335Z"
          fill={pathFillColor}
        />
      </g>
    </Icon>
  );
};
