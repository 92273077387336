/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { PlaylistDetail_CustomerFragmentDoc, PlaylistDetail_PlaylistFragmentDoc } from '../../../../../../components/playlists/PlaylistDetail/__generated__/PlaylistDetail.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PlaylistDetailsPageQueryVariables = Types.Exact<{
  customerHandle: Types.Scalars['String'];
  playlistId: Types.Scalars['ID'];
}>;


export type PlaylistDetailsPageQuery = { __typename: 'Query', customer?: { __typename: 'Customer', id: string, name: string } | null, playlist?: { __typename: 'Playlist', id: string, title: string, description?: string | null, playlistType: Types.PlaylistType, defaultDuration: number, media: Array<{ __typename: 'Media', id: string, title: string, size: any, duration: number, isDefaultDuration: boolean, type: string, createdAt: any }>, syncedDisplays: Array<{ __typename: 'Display', id: string }>, outOfSyncDisplays: Array<{ __typename: 'Display', id: string }>, syncingDisplays: Array<{ __typename: 'Display', id: string }>, removingDisplays: Array<{ __typename: 'Display', id: string }> } | null };


export const PlaylistDetailsPageDocument = gql`
    query PlaylistDetailsPage($customerHandle: String!, $playlistId: ID!) {
  customer: customerByHandle(handle: $customerHandle) {
    id
    ...PlaylistDetail_customer
  }
  playlist(id: $playlistId) {
    id
    ...PlaylistDetail_playlist
  }
}
    ${PlaylistDetail_CustomerFragmentDoc}
${PlaylistDetail_PlaylistFragmentDoc}`;

/**
 * __usePlaylistDetailsPageQuery__
 *
 * To run a query within a React component, call `usePlaylistDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistDetailsPageQuery({
 *   variables: {
 *      customerHandle: // value for 'customerHandle'
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistDetailsPageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlaylistDetailsPageQuery, PlaylistDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlaylistDetailsPageQuery, PlaylistDetailsPageQueryVariables>(PlaylistDetailsPageDocument, options);
      }
export function usePlaylistDetailsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlaylistDetailsPageQuery, PlaylistDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlaylistDetailsPageQuery, PlaylistDetailsPageQueryVariables>(PlaylistDetailsPageDocument, options);
        }
export type PlaylistDetailsPageQueryHookResult = ReturnType<typeof usePlaylistDetailsPageQuery>;
export type PlaylistDetailsPageLazyQueryHookResult = ReturnType<typeof usePlaylistDetailsPageLazyQuery>;
export type PlaylistDetailsPageQueryResult = ApolloReactCommon.QueryResult<PlaylistDetailsPageQuery, PlaylistDetailsPageQueryVariables>;