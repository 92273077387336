import { gql } from '@apollo/client';
import {
  Box,
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { chain, isEmpty } from 'lodash';
import { useCallback } from 'react';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { InfoAlert } from '~components/ui/Alert';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { ensure } from '~utils/types';
import { useBulkSyncDisplaysMutation } from './__generated__/BulkSyncDisplays.graphql';
import { BulkActionComponent } from './BulkActionModal';

export const BulkSyncDisplays: BulkActionComponent = ({
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const displaysToSyncByPlaylist = chain(displays)
    .filter((display) => Boolean(display.playlist?.current?.isOutOfSync))
    .map((display) => {
      return {
        playlistId: ensure(display.playlist?.current?.id),
        displayId: display.id,
      };
    })
    .groupBy((d) => d.playlistId)
    .value();

  const isSyncNeeded = !isEmpty(displaysToSyncByPlaylist);

  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const [bulkSyncDisplays] = useBulkSyncDisplaysMutation();
  const handleBulkSyncDisplays = useCallback(async () => {
    try {
      let totalCount = 0;
      for (const [playlistId, displays] of Object.entries(displaysToSyncByPlaylist)) {
        await bulkSyncDisplays({
          variables: {
            input: {
              playlistId,
              displayIds: displays.map((d) => d.displayId),
            },
          },
        });
        totalCount += displays.length;
      }

      toast({
        status: 'success',
        title: 'Sync to displays started',
        description: 'Playlist is being synced to displays, this may take a few minutes.',
      });

      if (displays.length === 1) {
        analytics.track('playlistUpdate');
      } else {
        analytics.track('displayBulkActionComplete', {
          action: 'updatePlaylist',
          displayCount: totalCount,
        });
      }

      await onSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Cannot start sync to displays',
        description: fromError(err, 'SyncToDisplays'),
      });
    }
  }, [analytics, bulkSyncDisplays, displaysToSyncByPlaylist, onSuccess, toast, displays.length]);

  return (
    <ModalContent>
      <ModalHeader>Sync displays</ModalHeader>
      <ModalCloseButton onClick={onCancel} />
      {isSyncNeeded ? (
        <>
          <ModalBody>
            <Text marginTop={4}>
              Syncing the same playlist to multiple devices on the same location may cause heavily
              increased network traffic.
            </Text>
            <InfoAlert mt={3}>
              {
                'Displays that are not capable of playing the selected playlist type will be skipped.'
              }
            </InfoAlert>
          </ModalBody>
          <ModalFooter>
            <HStack flex="1" alignItems="center">
              <BackToAllActionsButton onBack={onBack} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button variant="ghost" colorScheme="blue" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  marginLeft="3"
                  variant="solid"
                  colorScheme="blue"
                  onClick={handleBulkSyncDisplays}
                >
                  Apply
                </Button>
              </Box>
            </HStack>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalBody>
            <Stack spacing="4">
              <InfoAlert>
                {
                  "Since none of the playlists on these displays are out of sync, we can't sync any playlist."
                }
              </InfoAlert>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack flex="1" alignItems="center">
              <BackToAllActionsButton onBack={onBack} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button marginLeft="3" variant="solid" colorScheme="blue" onClick={onCancel}>
                  Ok
                </Button>
              </Box>
            </HStack>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
};

BulkSyncDisplays.graphql = {
  fragments: {
    BulkSyncDisplays_display: gql`
      fragment BulkSyncDisplays_display on Display {
        id
        playlist {
          current {
            id
            isOutOfSync
          }
        }
      }
    `,
  },
  mutations: {
    BulkSyncDisplays: gql`
      mutation BulkSyncDisplays($input: DisplayBulkUpdatePlaylistInput!) {
        displayBulkUpdatePlaylist(input: $input) {
          displays {
            id
          }
        }
      }
    `,
  },
};
