export enum TimeUnit {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'mins',
  Seconds = 'secs',
}

export type TimeType = {
  days: number;
  hours: number;
  mins: number;
  secs: number;
};

export function convertSecondsToTime(secs: number, level?: TimeUnit): TimeType {
  let days = 0;
  let hours = 0;
  let mins = 0;

  if (secs < 0) {
    return {
      days,
      hours,
      mins,
      secs: 0,
    };
  }

  if (!level || level === TimeUnit.Days) {
    days = Math.floor(secs / (24 * 3600));
    secs %= 24 * 3600;
  }

  if (!level || level === TimeUnit.Hours) {
    hours = Math.floor(secs / 3600);
    secs %= 3600;
  }

  if (!level || level === TimeUnit.Minutes) {
    mins = Math.floor(secs / 60);
    secs %= 60;
  }

  return {
    days,
    hours,
    mins,
    secs,
  };
}

export function convertTimeToSeconds({
  days = 0,
  hours = 0,
  mins = 0,
  secs = 0,
}: TimeType): number {
  if (days < 0 || hours < 0 || mins < 0 || secs < 0) {
    return 0;
  }

  return days * 24 * 3600 + hours * 3600 + mins * 60 + secs;
}

export function formatSecondsToTimeString(seconds: number, level?: TimeUnit) {
  const formatLabel = (count: number, label: string) => {
    if (!count) return '';
    if (count > 1) return `${count} ${label}s`;
    return `${count} ${label}`;
  };

  const { days, hours, mins, secs } = convertSecondsToTime(seconds, level);

  return `${formatLabel(days, 'day')} ${formatLabel(hours, 'hour')} ${formatLabel(
    mins,
    'min',
  )} ${formatLabel(secs, 'sec')}`;
}
