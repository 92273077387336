import { Button, ButtonProps } from '@chakra-ui/button';
import { Box, Input } from '@chakra-ui/react';
import { ChangeEvent, forwardRef, Ref, useCallback, useRef } from 'react';
import { PlaylistType } from '~graphql/__generated__/types';
import { UploadIcon } from './icons';

const ALLOWED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png'];
const ALLOWED_MIXED_MIME_TYPES = [...ALLOWED_IMAGE_MIME_TYPES, 'video/mp4'];

type Props = Omit<ButtonProps, 'onChange'> & {
  onChange: (file: FileList) => void;
  multiple?: boolean;
  playlistType: PlaylistType;
};

export const FileUploadButton = forwardRef(
  (
    {
      children,
      onChange,
      colorScheme = 'blue',
      multiple = true,
      playlistType = PlaylistType.Mixed,
      ...rest
    }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const ALLOWED_MIME_TYPES =
      playlistType === PlaylistType.Mixed ? ALLOWED_MIXED_MIME_TYPES : ALLOWED_IMAGE_MIME_TYPES;
    const fileUploadRef = useRef<HTMLInputElement | null>(null);

    const handleButtonClick = useCallback(() => {
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    }, []);

    const handleChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        onChange(event.target.files);
        // If we don't reset the value of the input and the user selects the same file,
        // nothing will happen and this might be perceived as a bug.
        // This way we allow users to select the same file twice, but we have to take care
        // of error handling in the component that uses this button
        const ref = fileUploadRef.current;
        if (ref) {
          ref.value = '';
        }
      },
      [onChange],
    );

    return (
      <Box>
        <Button
          ref={ref}
          leftIcon={<UploadIcon />}
          variant="ghost"
          colorScheme={colorScheme}
          onClick={handleButtonClick}
          {...rest}
        >
          {children}
        </Button>
        <Input
          type="file"
          hidden
          multiple={multiple}
          ref={fileUploadRef}
          accept={ALLOWED_MIME_TYPES.join(',')}
          onChange={handleChange}
        />
      </Box>
    );
  },
);
