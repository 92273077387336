/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type SetMediaDurationMutationVariables = Types.Exact<{
  input: Types.MediaUpdateInput;
}>;


export type SetMediaDurationMutation = { __typename: 'Mutation', mediaUpdate: Array<{ __typename: 'Media', id: string }> };


export const SetMediaDurationDocument = gql`
    mutation SetMediaDuration($input: MediaUpdateInput!) {
  mediaUpdate(input: $input) {
    id
  }
}
    `;
export type SetMediaDurationMutationFn = ApolloReactCommon.MutationFunction<SetMediaDurationMutation, SetMediaDurationMutationVariables>;

/**
 * __useSetMediaDurationMutation__
 *
 * To run a mutation, you first call `useSetMediaDurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMediaDurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMediaDurationMutation, { data, loading, error }] = useSetMediaDurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMediaDurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMediaDurationMutation, SetMediaDurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetMediaDurationMutation, SetMediaDurationMutationVariables>(SetMediaDurationDocument, options);
      }
export type SetMediaDurationMutationHookResult = ReturnType<typeof useSetMediaDurationMutation>;
export type SetMediaDurationMutationResult = ApolloReactCommon.MutationResult<SetMediaDurationMutation>;
export type SetMediaDurationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMediaDurationMutation, SetMediaDurationMutationVariables>;